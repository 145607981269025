
import { defineComponent } from "vue";

import { Network, Client } from "@verida/client-ts";
import { VaultAccount, hasSession } from "@verida/account-web-vault";
import { EnvironmentType } from '@verida/types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;
window.VaultAccount = VaultAccount;
window.hasSession = hasSession
window.Network = Network;
window.Client = Client;
window.EnvironmentType = EnvironmentType

export default defineComponent({
  name: "Sandbox",
  props: {
    msg: String,
  },
});

<template>
  <img alt="Verida logo" src="http://assets.verida.io/verida_logo.svg">
  <Sandbox />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Sandbox from './components/Sandbox.vue';

export default defineComponent({
  name: 'App',
  components: {
    Sandbox
  }
});
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: auto;
  max-width: 800px;
}
</style>


import { defineComponent } from 'vue';
import Sandbox from './components/Sandbox.vue';

export default defineComponent({
  name: 'App',
  components: {
    Sandbox
  }
});

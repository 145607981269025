<template>
  <b-container id="home">
    <b-row>
      <b-col>
        <b-card>
          <h1>Sandbox</h1>
          <h2><a name="top">Contents</a></h2>
          <ol>
            <li><a href="#intro">Introduction</a></li>
            <li><a href="#howto">How to use this sandbox</a></li>
            <li><a href="#auth">Authentication</a></li>
            <li><a href="#databases">Databases</a></li>
            <li><a href="#datastores">Datastores</a></li>
            <li><a href="#messaging">Messaging</a></li>
            <li><a href="#logout">Logging Out</a></li>
          </ol>
          <h2><a name="intro">Introduction</a></h2>
          <p>
            This simple sandbox demonstrates the basic concepts of the
            <a href="">Verida Protocol Client Library</a>.
          </p>
          <p>
            This demonstration authenticates a user with the Verida Vault to
            unlock an application `context` of encrypted data and messaging.
          </p>
          <p>The following links may also be helpful:
          <ol>
            <li><a href="https://developers.verida.io/docs/single-sign-on-sdk" target="_blank">Verida Single Sign On (SSO) documentation</a></li>
            <li><a href="https://developers.verida.io/docs/glossary"  target="_blank">Glossary (Common Terms)</a></li>
          </ol>
          </p>

          <h2><a name="howto">How to use this sandbox</a></h2>

          <p>
            This is an interactive demo where you open your Javascript console
            in your browser and copy / paste the commands to execute them right
            in your browser. The demonstration will authenticate you via the
            Verida VAult mobile application and then walk through range of
            capabilities offered by the Verida Protocol.
          </p>

          <p>
            It's strongly suggested you use the latest version of Google Chrome
            or Brave browser
          </p>

          <p>
            Learn more by reading the
            <a href="" target="_blank">Verida Client documentation</a>.
          </p>
          <h4>Getting Help</h4>
          <ol>
            <li>
              <a href="https://github.com/verida" target="_blanck">Github</a>
            </li>
            <li>
              <a href="https://discord.com/invite/qb6vS43" target="_blanck"
                >Discord Chat</a
              >
            </li>
            <li>
              <a href="https://www.verida.io/" target="_blanck"
                >Verida website</a
              >
            </li>
          </ol>

          <p>This demo uses the Verida Testnet.</p>

          <h2><a name="auth" id="authenticate">Authentication</a></h2>

          <p>We require some dependencies:</p>

          <pre>
            import { Network } from '@verida/client-ts'
            import { VaultAccount } from '@verida/account-web-vault'
            import { EnvironmentType } from '@verida/types'
          </pre>

          <ul>
            <li>
              <strong>Network</strong> is a helper class that allows for quickly
              initializing an application Context for a user.
            </li>
            <li>
              <strong>VaultAccount</strong> is a helper library that prompts a
              user to sign into the application on their mobile device by
              scanning a QR code.
            </li>
          </ul>

          <p>
            Both these properties are already linked and available via
            <code>window</code> in the Javascript console.
          </p>

          <p>We also specify two constants:</p>

          <pre>
            const VERIDA_LOGO_URL= 'https://assets.verida.io/verida_login_request_logo_170x170.png'
            const CONTEXT_NAME = 'Verida: Sandbox Demo'
          </pre>
          <ul>
            <li>
              <strong>CONTEXT_NAME</strong> The name of the application context
              to unlock. This is typically the name of your application. Opening
              a different application context will open a completely isolated
              secure enclave for the user.
            </li>
          </ul>

          <p>
            Let's put this together and ask the user to authenticate with this
            sandbox:
          </p>
          <pre>
            const VERIDA_LOGO_URL= 'https://assets.verida.io/verida_login_request_logo_170x170.png'
            const CONTEXT_NAME = 'Verida: Sandbox Demo'

            const account = new VaultAccount({
              request: {
                logoUrl: VERIDA_LOGO_URL,
                deviceId: 'Sandbox - Web Browser'
              },
            })

            const context = await window.Network.connect({
              client: {
                  environment: EnvironmentType.TESTNET
              },
              account: account,
              context: {
                  name: CONTEXT_NAME
              }
            })

            const did = await account.did()
            console.log("User is connected with DID: " + did)
          </pre>
          <p>
            Copy and paste the above code into the Javascript console and you
            will be prompted to scan a QR code to start the authentication
            process on your mobile device.
          </p>

          <p>
            Once authentication is complete the QR modal will disappear and the
            console will show your Verida DID.
          </p>

          <h4>What's going on here?</h4>

          <p>
            We first create a <code>VeridaVault</code> account instance that
            encapsulates a user account. This is configured with default
            database and message servers. These are
            <a href="">Verida Storage Nodes</a> that provide encrypted database
            storage for end users. Your application must spin up one of these as
            a default server for new users. In the future, users will be able to
            spin up their own or select third party hosting providers.
          </p>

          <p>
            Next we connect the account instance to the Verida Network and
            connect to the requested application context
            (<code>CONTEXT_NAME</code>). Internally, this process makes a
            <code>connect()</code> call on the <code>account</code> instance to
            start the authentication flow.
          </p>

          <p>
            Once the authentication flow is complete, the application has a
            <code>context</code> object available for all database and message
            operations using the Verida Protocol. Skip to the
            <a href="#databases">Databases</a> section to start using your
            application context.
          </p>

          <p>
            Start by initializing Web3 in the browser and configuring the Verida
            Datastore library.
          </p>

          <h2><a name="databases" id="databases">Databases</a></h2>

          <p>
            Let's open a database (or create if it doesn't exist), save a
            record, then view the record.
          </p>

          <p>
            We can also view the data locally (unencrypted) and on the remote
            server (encrypted) using private keys of the user
          </p>

          <pre>
            // Open / create a test database
            const db = await context.openDatabase('test_db');
            const item = await db.save({
              hello: 'world'
            });
            const items = await db.getMany();
            console.log(items);

            // Show the data stored in the local (unencrypted) database and the remote (encrypted) database
            const localData = await db._localDb.allDocs({include_docs: true});
            console.log("Local data", localData);

            const remoteData = await db._remoteDbEncrypted.allDocs({include_docs: true});
            console.log("Remote data", remoteData)
          </pre>

          <h2><a name="datastores" id="datastores">Datastores</a></h2>

          <p>
            Datastores provide a fixed schema that must be validated before a
            record is saved. This allows data to be shared / synchronized across
            multiple applications that agree to use the same schema.
          </p>

          <pre>
            // Lets open a datastore with a defined schema, in this the `social/contact` datastore
            const contacts = await context.openDatastore('https://schemas.testnet.verida.io/social/contact/schema.json')

            // Lets save a result (with an intentional error where the email is invalid)
            let contact = {
              firstName: 'John',
              lastName: 'Smith',
              email: 'john__smith.com'
            }
            let success = await contacts.save(contact);

            if (!success) {
              console.error(contacts.errors);
            } else {
              console.log("Contact saved");
            }

            // Fix the email and save properly
            contact.email = 'john@smith.com';
            await contacts.save(contact);

            // View the saved record
            await contacts.getMany();
          </pre>

          <h2><a name="messaging" id="messaging">Messaging</a></h2>

          <p>
            Every application has a built-in inbox for receiving messages and
            outbox for sending messages. This allows users and applications to
            send data between each other knowing nothing than the other user's
            DID and application name.
          </p>

          <p>
            This example sends a contact record to a user's Verida Vault mobile
            application.
          </p>

          <p>
            Open the Verida Vault mobile application and copy your DID (from the
            home screen) into the code below
          </p>

          <pre>
          const type = 'inbox/type/dataSend'

          // Generate an inbox message containing an array of data
          const data = {
            data: [
              {
                name: 'Vitalik Buterin',
                firstName: 'Vitalik',
                lastName: 'Buterin',
                email: 'me@vitalik.eth',
                schema: 'https://common.schemas.verida.io/social/contact/v0.1.0/schema.json'
              }
            ]
          }

          const message = 'New contact: ' + data.data[0].name;
          const config = {
            recipientContextName: 'Verida: Vault'
          }

          const messaging = await context.getMessaging()
          await messaging.send(did, type, data, message, config)
          </pre>

          <p>Note: <code>did</code> is already defined in the previous code (above).</p>

          <h2><a name="logout" id="logout">Logging Out</a></h2>
          <p>
            Once you are done with your session, you will want to log out. This
            is done by disconnecting the account.
          </p>

          <pre>
await context.getAccount().disconnect(CONTEXT_NAME)
          </pre>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { Network, Client } from "@verida/client-ts";
import { VaultAccount, hasSession } from "@verida/account-web-vault";
import { EnvironmentType } from '@verida/types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;
window.VaultAccount = VaultAccount;
window.hasSession = hasSession
window.Network = Network;
window.Client = Client;
window.EnvironmentType = EnvironmentType

export default defineComponent({
  name: "Sandbox",
  props: {
    msg: String,
  },
});
</script>


<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}

a {
  color: #42b983;
}

pre {
  padding: 10px;
  background: #f6f6f6;
  border-radius: 20px;
  margin: 0px;
  overflow-x: scroll;
  border: 1px solid #999;
}

.btn.disconnect {
  float: right;
  top: 5px;
  right: 5px;
}
.user {
  margin-bottom: 10px;
}
.docs-list {
  height: 40vh;
  overflow: scroll;
  margin: 10px 0px;
}
.log {
  overflow: scroll;
  height: 20vh;
}
</style>
